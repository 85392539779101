import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SystemStyleObject } from '@chakra-ui/react';
import Autocomplete, { Item } from '@components/autocomplete/Autocomplete';
import { buildItem } from '@components/autocomplete/utils';
import { Size } from '@components/common/size';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'next-i18next';
import { City } from 'src/api/types/cities';

import { SearchBarFormType } from './types';

interface CityPickProps {
  cities: City[];
  currentCity?: string;
  size?: Size;
  inputSX?: SystemStyleObject;
  isCityResetting: boolean;
  onResetCitiesCompleted: () => void;
  classNameInput?: string;
  classNameContainer?: string;
  rightIconClasses?: string;
  applySxRightElement?: boolean;
}

const CityPick: FC<CityPickProps> = ({
  cities,
  size = 'md',
  currentCity,
  inputSX,
  isCityResetting,
  classNameInput,
  rightIconClasses,
  classNameContainer,
  onResetCitiesCompleted,
  applySxRightElement
}) => {
  const { t } = useTranslation('search');
  const citiesClone = [...cities];

  const { control } = useFormContext<SearchBarFormType>();

  if (!cities.length) {
    citiesClone.push({
      cod: 'no-city',
      country: 'no-city',
      id: 'no-city',
      name: t('city.no-city'),
      _id: 'no-city'
    });
  }

  const buildCityItem = ({ id, _id, name }: City) => ({ value: id || _id, label: name });
  const items = buildItem(buildCityItem, citiesClone);

  return (
    <Controller
      control={control}
      name="city"
      render={({ field: { value, onChange, ref } }) => (
        <Autocomplete
          ref={ref}
          size={size}
          setCurrentValue={(city?: Item) => {
            onChange(city?.value || '');
          }}
          onResetComplete={onResetCitiesCompleted}
          shouldReset={isCityResetting}
          initialValue={items.find(({ value: itemValue }) => itemValue === currentCity)}
          currentValue={items.find(({ value: itemValue }) => itemValue === value)}
          items={items}
          sx={{
            w: '100%',
            '&:hover': {
              opacity: 0.7
            },
            ...inputSX
          }}
          placeholder={t('city.placeholder')}
          classNameInput={classNameInput}
          className={classNameContainer}
          rightIcon={<ChevronDownIcon className={`h-4 w-4 stroke-2 text-rentennials-input ${rightIconClasses}`} />}
          applySxRightElement={applySxRightElement}
        />
      )}
    />
  );
};

export default CityPick;
