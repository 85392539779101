import { forwardRef, useMemo } from 'react';
import { SystemStyleObject } from '@chakra-ui/react';
import Autocomplete, { Item } from '@components/autocomplete/Autocomplete';
import { buildItem } from '@components/autocomplete/utils';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { tw } from '@utils/tailwind-utils';
import { useTranslation } from 'next-i18next';

import { Size } from '../size';

import { CountryFlagType, countryList } from './countries';
import { CountryCode } from './types';

interface CountryAutocompleteProps {
  fixedCountries?: CountryCode[];
  onChange: (countryValue: string) => void;
  selectedValue?: string;
  initialValue?: string;
  size?: Size;
  inputSX?: SystemStyleObject;
  showLeftIcon?: boolean;
  rightIconClasses?: string;
  classNameContainer?: string;
  classNameInput?: string;
  applySxRightElement?: boolean;
}

const CountryAutocomplete = forwardRef<HTMLInputElement, CountryAutocompleteProps>(
  (
    {
      fixedCountries,
      onChange,
      selectedValue,
      initialValue,
      size,
      inputSX,
      showLeftIcon,
      classNameContainer,
      classNameInput,
      rightIconClasses,
      applySxRightElement
    },
    ref
  ) => {
    const { t } = useTranslation('countries');
    const items = useMemo(() => {
      const buildCountryItems = ({ countryCode, unicode }: CountryFlagType) => ({
        value: countryCode,
        label: t(countryCode),
        leftItemIcon: (
          <span className={tw(size === 'sm' ? 'text-xs' : 'text-sm')} dangerouslySetInnerHTML={{ __html: unicode }} />
        )
      });
      return buildItem(
        buildCountryItems,
        fixedCountries ? countryList.filter(({ countryCode }) => fixedCountries.includes(countryCode)) : countryList
      ).sort((c1, c2) => {
        return c1.label > c2.label ? 1 : c1.label === c2.label ? 0 : -1;
      });
    }, [fixedCountries, size, t]);

    return (
      <Autocomplete
        ref={ref}
        size={size}
        setCurrentValue={(city?: Item) => {
          onChange(city?.value || '');
        }}
        initialValue={items.find(({ value: itemValue }) => itemValue === initialValue)}
        currentValue={items.find(({ value: itemValue }) => itemValue === selectedValue)}
        items={items}
        sx={{
          w: '100%',
          '&:hover': {
            opacity: 0.7
          },
          ...inputSX
        }}
        placeholder={t('countries:input.placeholder')}
        rightIcon={<ChevronDownIcon className={`"h-4 w-4 stroke-2 ${rightIconClasses}`} />}
        showLeftIcon={showLeftIcon}
        className={classNameContainer}
        classNameInput={classNameInput}
        applySxRightElement={applySxRightElement}
      />
    );
  }
);

export default CountryAutocomplete;
